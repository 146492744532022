<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <h5>Delivery Incentives</h5>
      </b-card-title>
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>
      <div v-else-if="!loading && chartData.records.length === 0">
        <p>No infromation available.</p>
      </div>
      <b-table
        v-else
        bordered
        :items="chartData.records"
        :fields="tableFields"
        striped
        class="compact-table"
        hover
        responsive="sm"
      >
        <template #thead-top>
          <b-tr>
            <b-th
              v-for="(value, index) in chartData.columns1"
              :key="index"
              :colspan="value"
            >
              <span
                :class="['delivery person', 'total incentives'].includes(index) ? 'sr-only' : '' "
              >
                {{ index }}
              </span>
            </b-th>
          </b-tr>
        </template>
      </b-table>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BTable, BSpinner, BCardTitle, BTr, BTh,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardBody, BTable, BSpinner, BCardTitle, BTr, BTh,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableFields() {
      return this.$props.chartData.columns.map(item => ({ key: item, label: item.split('.').pop() }))
    },
  },
}
</script>
