<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header>
        <h4> Delivery Dashboard </h4>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="4">
            <b-form-group label="Date Range">
              <flat-picker
                v-model="filters.date"
                :config="{ mode: 'range'}"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      v-if="initialError"
      no-body
    >
      <b-card-body>
        <b-alert
          variant="danger"
          :show="initialError ? true : false"
        >
          <div class="alert-body">
            <p class="mb-0">
              {{ initialError }}
            </p>
          </div>
        </b-alert>
      </b-card-body>
    </b-card>
    <div v-if="result && !initialError">
      <delivery-incentives
        :loading="initialLoader"
        :chart-data="result.incentives_data"
      />
      <salesman-and-amount-wise-delivery-state
        :loading="initialLoader"
        :chart-data="result.delivery_person_by_amount"
      />
      <salesman-and-area-wise-delivery-state
        :loading="initialLoader"
        :chart-data="result.delivery_person_by_area"
      />
      <created-time-wise-state
        :loading="initialLoader"
        :chart-data="result.bills_by_created_time"
      />
      <bill-count-by-users
        :loading="initialLoader"
        :chart-data="result.bills_by_generated_person"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BCard, BCardHeader, BRow, BCol, BCardBody, BFormGroup, BAlert,
} from 'bootstrap-vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import moment from 'moment-timezone'
import SalesmanAndAmountWiseDeliveryState from '@/components/Delivery/DeliveryDashboard/SalesmanAndAmountWiseDeliveryState.vue'
import SalesmanAndAreaWiseDeliveryState from '@/components/Delivery/DeliveryDashboard/SalesmanAndAreaWiseDeliveryState.vue'
import CreatedTimeWiseState from '@/components/Delivery/DeliveryDashboard/CreatedTimeWiseState.vue'
import BillCountByUsers from '@/components/Delivery/DeliveryDashboard/BillCountByUsers.vue'
import DeliveryIncentives from '@/components/Delivery/DeliveryDashboard/DeliveryIncentives.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BCardBody,
    BFormGroup,
    FlatPicker,
    SalesmanAndAmountWiseDeliveryState,
    SalesmanAndAreaWiseDeliveryState,
    BAlert,
    CreatedTimeWiseState,
    BillCountByUsers,
    DeliveryIncentives,
  },
  data() {
    return {
      filters: {
        date: null,
      },
      result: null,
      initialLoader: false,
      initialError: null,
    }
  },
  watch: {
    filters: {
      handler() {
        this.fetchDashboardData()
      },
      deep: true,
    },
  },
  created() {
    const date = moment().subtract(1, 'day').format('YYYY-MM-DD')
    this.filters = { date: `${date} to ${date}` }
  },
  methods: {
    async fetchDashboardData() {
      try {
        this.initialLoader = true
        this.initialError = null
        const [fromDate, toDate] = this.filters.date?.split(' to ') || ''
        const { data } = await axios.get('/delivery/delivery_bills_dashboard', {
          params: {
            ...(fromDate && toDate ? {
              date_from: fromDate,
              date_to: toDate,
            } : {}),
          },
        })
        this.result = data
      } catch (error) {
        this.initialError = error?.response?.data?.error || 'Something went wrong!'
      } finally {
        this.initialLoader = false
      }
    },
  },

}
</script>
