<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <h5>Delivered By vs Area</h5>
      </b-card-title>
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>
      <div v-else>
        <b-table-simple
          class="compact-table"
          fixed
        >
          <b-thead>
            <b-tr>
              <b-th> Delivered By </b-th>
              <b-th> Area </b-th>
              <b-th> Total </b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="chartData.records.length">
            <b-tr
              v-for="data in chartData.records"
              :key="data.delivered_by"
            >
              <b-td> {{ data.delivered_by }} </b-td>
              <b-td class="temp">
                <div
                  v-for="item in Object.keys(data)"
                  :key="item"
                >
                  <b-row
                    v-if="item !== 'total' && item !== 'delivered_by' && data[item]"
                  >
                    <b-col>
                      {{ item }}
                    </b-col>
                    <b-col>
                      {{ data[item] }}
                    </b-col>
                  </b-row>
                </div>
              </b-td>
              <b-td>
                {{ data.total }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tbody v-else>
            <b-tr class="empty-table-row">
              <b-td
                class="text-center py-2"
                colspan="3"
              >
                There are no records to show
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BSpinner, BCardTitle, BRow, BCol, BTableSimple, BTbody, BTr, BTd, BTh, BThead,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardBody, BSpinner, BCardTitle, BRow, BCol, BTableSimple, BTbody, BTr, BTd, BTh, BThead,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.empty-table-row {
  background: #fafafc;
}
</style>
