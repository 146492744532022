<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        <h5>Delivered By vs Bill Amount</h5>
      </b-card-title>
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>
      <div v-else>
        <b-button-group>
          <b-button
            class="mb-1"
            size="sm"
            :variant=" mode==='billDate' ? 'secondary' : 'outline-secondary'"
            @click="mode='billDate'"
          >
            By Bill Date
          </b-button>
          <b-button
            class="mb-1"
            size="sm"
            :variant=" mode==='deliveryDate' ? 'secondary' : 'outline-secondary'"
            @click="mode='deliveryDate'"
          >
            By Delivery Date
          </b-button>
        </b-button-group>
        <b-table
          :items="records"
          :fields="tableFields"
          striped
          hover
          fixed
          show-empty
          class="compact-table"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BTable, BSpinner, BCardTitle,
  BButton, BButtonGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardBody, BTable, BSpinner, BCardTitle, BButton, BButtonGroup,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: 'billDate',
      sortBy: 'total',
      sortDesc: true,
    }
  },
  computed: {
    records() {
      if (this.mode === 'billDate') {
        return this.$props.chartData.by_bill_date.records
      }
      return this.$props.chartData.by_delivery_date.records
    },
    columns() {
      if (this.mode === 'billDate') {
        return this.$props.chartData.by_bill_date.columns
      }
      return this.$props.chartData.by_delivery_date.columns
    },
    tableFields() {
      return this.columns.map(head => ({
        key: head,
        sortable: true,
        label: head,
      }))
    },

  },
}
</script>
